import { Box,Button,Typography } from '@mui/material';
import bg1 from "./02.jpg"
import bg2 from "./04.jpg"
import bg3 from "./bg1.jpg"
import bg4 from "./bg2.jpg"
import logo from "./output-onlinepngtools.png"
import cultureImage from "./cultureImage.png"
import bluebg from "./mongolian.jpg"
import facebook from "./facebook.png"
import instagram from "./instagram.png"
import x from "./x.png"


const styles = {
  container: {
    height: "100vh",
    width: "100vw",
    backgroundSize: "100vw, 40vh"
  },
  header: {
    height: "35vh",
    width: "100vw",
    backgroundImage: `url(${bg4})`,
    backgroundRepeat: "noRepeat",
    backgroundSize: "100% 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  logo: {
    height: "28vh",
    width: "15vw",
    marginLeft: "7vw",
    marginBottom: "7vh"
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "5vh"
  },
  headerLetter: {
    size: "10px",
    wordWrap: "break-word",
    marginLeft: "2vw",
    marginRight: "2vw",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "green",
    height: "5vh",
    width: "10vw",
    marginBottom: "5vh"
  },
  cart: {
    marginRight: "5vw",
  },
  // first section
  line: {
    marginTop: "5vh",
    height: "0.1vh",
    width: "86vw",
    marginLeft: "7vw",
    backgroundColor: "black",
  },
  firstSection: {
    marginLeft: "7vw",
    marginTop: "5vh",
    display: "flex",
    flexDirection: "row",
    width: "80vw"
  },
  firstSectionImage: {
    width: "40vw",
    height: "45vh"
  },
  firstSectionContainer: {
    marginLeft: "3vw",
    height: "45vh",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  MongolianText: {
    fontWeight: "bold",
  },
  aCenterText: {
    fontWeight: "bold",
    color: "#0171bb"
  },
  // second section
  secondSection: {
    // backgroundImage: `url(${bg1})`,
    backgroundColor: "#CFDFE3",
    marginTop: "8vh",
    display: "flex",
    backgroundSize: "100% 100%",
    flexDirection: "column",
  },
  mcccnaTextContainer:{
    width: "100vw",
    display: "flex",
    justifyContent: "center",
  },
  mcccnaText: {
    marginTop: "5vh",
    fontWeight: "bold",
    color: "#0171bb",
  },
  row: {
    marginTop: "5vh",
    marginLeft: "7vw",
    width: "86vw",
    height: "500px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  secondSectionImageContainer: {
    width: "27vw",
  },
  secondSectionImage: {
    width: "100%",
  },
  secondSectiontitle: {
    color: "#0171bb",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    marginTop: "3vh"
  },
  secondSectionSubTitle: {
    color: "#0171bb",
    marginTop: "3vh"
  },
  // third section start
  thirdSection: {
    marginTop: "7vh",
    display: "flex",
    flexDirection: "row",
    marginLeft: "7vw",
    width: "86vw",
    justifyContent: "space-between",
    paddingBottom: "10vh"
  },
  thirdSectionTextContainer: {
    height: "50vh",
    width: "40vw"
  },
  thirdSectionText: {
    fontWeight: "bold",
  },
  thirdSectionImage: {
    width: "40vw"
  },
  // forth section start
  forthSection: {
    height: "40vh",
    width: "100v",
    display: "flex",
  },
  forthSectionBox: {
    backgroundImage: `url(${bluebg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    width: "34vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  forthSectionTitle: {
    color: "white",
    fontWeight: "bold",
  },
  forthSectionSubtitle: {
    color: "white"
  },
  sds: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "black, 5px, solid",
  },
  //footer
  footer: {
    height: "70vh",
    width: "100vw",
    backgroundColor: "black",
  },
  socialMedia: {
    paddingTop: "1vw",
    height: "4vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
  },
  facebook: {
    marginRight: "20px"
  },
  instagram: {
    marginRight: "20px"
  },
  x: {

  }
}

function App() {
  return (
    <Box style={styles.container}>
      <Box style={styles.header}> 
        <img style={styles.logo} src={logo}/>
        <Box style={styles.textContainer}>
          <Typography variant="h6" align='center' style={styles.headerLetter}>LANGUAGE SCHOOL</Typography>
          <Typography variant="h6" align='center' style={styles.headerLetter}>EVENTS & PROGRAMS</Typography>
          <Typography variant="h6" align='center' style={styles.headerLetter}>VISIT</Typography>
          <Typography variant="h6" align='center' style={styles.headerLetter}>GET INVOLVED</Typography>
          <Typography variant="h6" align='center' style={styles.headerLetter}>MONGOLIAN SHOP</Typography>
        </Box>
        <Button variant="contained" style={styles.button}>
          <Typography>DONATE</Typography>
        </Button>
        <Box style={styles.cart}>

        </Box>
      </Box>
      <Box style={styles.line}>

      </Box>
      <Box style={styles.firstSection}>
        <img style={styles.firstSectionImage} src={cultureImage} />
        <Box style={styles.firstSectionContainer}>
          <Typography variant='h3' style={styles.MongolianText}>Mongolian Cultural & Community Center of North America</Typography>
          <Typography variant="h3" style={styles.aCenterText}>A Center for people who love all things Mongolian.. and more!</Typography>
        </Box>
      </Box>
      <Box style={styles.secondSection}>
        <Box style={styles.mcccnaTextContainer}>
          <Typography variant="h3" style={styles.mcccnaText}>What the MCCCNA has to offer </Typography>
        </Box>
          <Box style={styles.row}>
            <Box style={styles.secondSectionImageContainer}>
              <img src={cultureImage} style={styles.secondSectionImage}/>
              <Typography variant='h4'style={styles.secondSectiontitle} >
                Learn Mongolian
              </Typography>
              <Typography variant='subtitle1' style={styles.secondSectionSubTitle}>
                Enroll in classes at the Seattle Japanese Language School, where we've been teaching for 120 years!

                Adult Online & Youth In-Person classes available
              </Typography>
            </Box>
            <Box style={styles.secondSectionImageContainer}>
            <img src={cultureImage} style={styles.secondSectionImage}/>
              <Typography variant='h4' style={styles.secondSectiontitle}>
                Learn Mongolian
              </Typography>
              <Typography variant='subtitle1' style={styles.secondSectionSubTitle}>
                Enroll in classes at the Seattle Japanese Language School, where we've been teaching for 120 years!

                Adult Online & Youth In-Person classes available
              </Typography>
            </Box>
            <Box style={styles.secondSectionImageContainer}>
            <img src={cultureImage} style={styles.secondSectionImage}/>
              <Typography variant='h4' style={styles.secondSectiontitle}>
                Learn Mongolian
              </Typography>
              <Typography variant='subtitle1' style={styles.secondSectionSubTitle}>
                Enroll in classes at the Seattle Japanese Language School, where we've been teaching for 120 years!

                Adult Online & Youth In-Person classes available
              </Typography>
            </Box>
          </Box>
          <Box style={styles.row}>
            <Box style={styles.secondSectionImageContainer}>
            <img src={cultureImage} style={styles.secondSectionImage}/>
              <Typography variant='h4' style={styles.secondSectiontitle}>
                Learn Mongolian
              </Typography>
              <Typography variant='subtitle1' style={styles.secondSectionSubTitle}>
                Enroll in classes at the Seattle Japanese Language School, where we've been teaching for 120 years!

                Adult Online & Youth In-Person classes available
              </Typography>
            </Box>
            <Box style={styles.secondSectionImageContainer}>
            <img src={cultureImage} style={styles.secondSectionImage}/>
              <Typography variant='h4' style={styles.secondSectiontitle}>
                Learn Mongolian
              </Typography>
              <Typography variant='subtitle1' style={styles.secondSectionSubTitle}>
                Enroll in classes at the Seattle Japanese Language School, where we've been teaching for 120 years!

                Adult Online & Youth In-Person classes available
              </Typography>
            </Box>
            <Box style={styles.secondSectionImageContainer}>
            <img src={cultureImage} style={styles.secondSectionImage}/>
              <Typography variant='h4' style={styles.secondSectiontitle}>
                Learn Mongolian
              </Typography>
              <Typography variant='subtitle1' style={styles.secondSectionSubTitle}>
                Enroll in classes at the Seattle Japanese Language School, where we've been teaching for 120 years!

                Adult Online & Youth In-Person classes available
              </Typography>
            </Box>
        </Box>
      </Box>
      <Box style={styles.thirdSection}>
        <Box style={styles.thirdSectionTextContainer}>
          <Typography variant='h3' style={styles.thirdSectionText}>
            Join us at upcoming MCCCNA and community events!
          </Typography>
        </Box>
        <img src={cultureImage} style={styles.thirdSectionImage} />
      </Box>
      <Box style={styles.forthSection}>
        <Box style={styles.forthSectionBox}>
          <Typography variant="h3" style={styles.forthSectionTitle}>
              Nikkei News
          </Typography>
          <Typography variant="subtitle2" style={styles.forthSectionSubtitle}>
            READ OUR BLOG
          </Typography>
        </Box>
        <Box style={styles.forthSectionBox}>
          <Typography variant="h3" style={styles.forthSectionTitle}>
            Become a Member
          </Typography>
          <Typography variant="subtitle2" style={styles.forthSectionSubtitle}>
            JOIN NOW
          </Typography>
        </Box>
        <Box style={styles.forthSectionBox}>
          <Typography variant="h3" style={styles.forthSectionTitle}>
            Donate today
          </Typography>
          <Typography variant="subtitle2" style={styles.forthSectionSubtitle}>
            SUPPORT OUR PROGRAMS
          </Typography>
        </Box>
      </Box>
      <Box style={styles.footer}>
        <Box style={styles.socialMedia}>
          <img src={facebook} style={styles.facebook} />
          <img src={instagram} style={styles.instagram} />
          <img src={x} style={styles.x} />
        </Box>
      </Box>
    </Box>
  );
}

export default App;
